
import { Options, mixins } from "vue-class-component";
import Layout from "@/components/Layout.vue";

import { VueUploadItem } from "vue-upload-component";
import { axiosInstanceToken } from "@/plugins/axios";
import { Endpoint } from "@/api/endpoints";
import TwoFactorValidated from "@/mixins/TwoFactorValidated";
import Verification from "@/components/Verification/index.vue";
import Upload from "@/components/Upload/index.vue";
import { File } from "@/models/File";
import { CustomSort } from "@/models/Sort";

const sorters = {
  DATE_ASCENDING: (a: any, b: any) =>
    new Date(a.fileCreatedAt).getTime() - new Date(b.fileCreatedAt).getTime(),
  DATE_DESCENDING: (a: any, b: any) =>
    new Date(b.fileCreatedAt).getTime() - new Date(a.fileCreatedAt).getTime(),
};

@Options({
  components: {
    Layout,
    Upload,
    Verification,
  },
})
export default class VendorUpload extends mixins(TwoFactorValidated) {
  error: false;
  loadingUploadFile = false;
  config = {
    sortSelected: {
      key: "fileCreatedAt",
      isAsc: true,
    },
    tableClass: "",
  };
  files: VueUploadItem[] = [];
  fileRemove = "";

  verifications = {
    files: "files",
    getFiles: (id: string): string => Endpoint.VENDOR_FILES(id).medsurveyUrl,
    sendCode: (id: string): string => Endpoint.VENDOR_SEND_CODE(id).medsurveyUrl,
    generateCode: (id: string): string => Endpoint.VENDOR_GENERATE_SEND_CODE(id).medsurveyUrl,
    applyCode: (id: string): string => Endpoint.VENDOR_APPLY_CODE(id).medsurveyUrl,
  };

  get sortedRows(): File[] {
    return this.rows.sort(
      sorters[
        this.config.sortSelected.isAsc ? "DATE_ASCENDING" : "DATE_DESCENDING"
      ]
    );
  }

  async inputFilter(newFile: VueUploadItem): Promise<void> {
    this.loadingUploadFile = true;
    await this.uploadFile(newFile.file);
    this.loadingUploadFile = false;
  }

  async uploadFile(file: any): Promise<void> {
    let formData = new FormData();
    formData.append("file", file);

    return await await axiosInstanceToken.post(
      Endpoint.VENDOR_FILE_UPLOAD(this.id).medsurveyUrl,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  async onUploadFiles(payload: VueUploadItem[]): Promise<void> {
    this.files = [...payload];
    this.loadingUploadFile = true;
    await Promise.all(
      this.files.map(async (fileConfig: VueUploadItem) => {
        await this.uploadFile(fileConfig.file);
      })
    );
    this.loadingUploadFile = false;

    this.files = [];

    await this.getFiles();
  }

  async onFileRemove(file: File): Promise<void> {
    this.fileRemove = file.fileId;

    try {
      await axiosInstanceToken.delete(
        Endpoint.VENDOR_FILE_DELETE(this.id, file.fileId).medsurveyUrl
      );
      this.getFiles();
    } catch (error: any) {
      // this.showError("notValid");
    }

    this.fileRemove = "";
  }

  toSort(payload: CustomSort): void {
    this.config.sortSelected = Object.assign({}, payload);
  }
}
